import { render, staticRenderFns } from "./Flights.vue?vue&type=template&id=87a0be16&scoped=true"
import script from "./Flights.vue?vue&type=script&lang=js"
export * from "./Flights.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87a0be16",
  null
  
)

export default component.exports