<template>
  <div>
    <h1 class="border-bottom pb-4">{{ $t('ready_for_booking') }}</h1>
    <div class="mt-3">
      <div class="row">
        <div class="col-lg-12">
          <label for="search-flight">{{ $t('quick_bar.select_flight_title') }}</label>
          <div class="position-relative">
            <div class="position-relative">
              <input type="text" id="search-flight" class="form-control custom-search shadow-sm pe-5" :placeholder="$t('quick_bar.placeholder_select_flight')">
              <i class="bi bi-chevron-down position-absolute icon-action-flight" style="top: 50%; right: 15px; transform: translateY(-50%);" @click="toggleFlightList"></i>
            </div>
            <div class="flights-list custom-flights-list-shadow">
              <!-- Flight List -->
              <div v-if="showFlightList" class="flight-list bg-white rounded">
                <SelectFlightItem v-for="itemFlight in flightItems" :item-flight="itemFlight" :key="itemFlight.flight_id"></SelectFlightItem>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectFlightItem from "@/views/templates/SelectFlightItem.vue";

export default {
  components: {
    SelectFlightItem
  },
  data() {
    return {
      showFlightList: false,  // Toggle flight list visibility
      selectedOption: null,
      flightItems: [],
      options: []
    };
  },
  mounted() {
    // Initialize Bootstrap tooltips


    this.getHomeFlights()
  },
  methods: {
    toggleFlightList() {
      // Toggle visibility of the flight list
      this.showFlightList = !this.showFlightList;
      if (this.showFlightList && this.flightItems.length === 0) {
        // Fetch flight items if the list is shown and not loaded
        this.getHomeFlights();
      }
    },
    getHomeFlights() {
      this.$http.get(`/manage-services/flights/get-flights`)
          .then((res) => {
            this.flightItems = res.data.data
          }).finally(() => {
        const tooltipTriggerList = [].slice.call(this.$el.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));

        this.showFlightList = true
      })
    },
  }
}
</script>
