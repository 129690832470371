<template>
  <div class="bg-light border-top">
    <div class="container pb-5 pt-5">
      <div class="row">
        <div class="col-lg-4">
          <strong><h3>{{ $t('footer.more_information') }}</h3></strong>
          <ul class="list-unstyled footer-links">
            <li v-for="item in firstSectionFooter" class="p-2"><a href="#!"><i class="bi bi-chevron-right"></i> {{ $t(item.lang_key) }}</a></li>
          </ul>
        </div>
        <div class="col-lg-4">
          <strong><h3>{{ $t('footer.fly_higher') }}</h3></strong>
          <ul class="list-unstyled footer-links">
            <li v-for="item in secondSectionFooter" class="p-2"><a href="#!"><i class="bi bi-chevron-right"></i> {{ $t(item.lang_key) }}</a></li>
          </ul>
        </div>
        <div class="col-lg-4">
          <strong><h3>{{ $t('footer.my_skyballoons') }}</h3></strong>
          <ul class="list-unstyled footer-links">
            <li v-for="item in thirdSectionFooter" class="p-2"><a href="#!"><i class="bi bi-chevron-right"></i> {{ $t(item.lang_key) }}</a></li>
          </ul>
          <div class="mt-3 mb-3 border-bottom"></div>
          <h4>{{ $t('footer.follow_us_on_social_media') }}</h4>
          <div>
            <ul class="list-unstyled">
              <li
                  v-for="icon in socialMediaIcons"
                  :key="icon"
                  class="icon-footer d-inline p-3"
              >
                <a href="#!"><i :class="icon"></i></a>
              </li>
            </ul>
          </div>

          <h3 class="h5 fw-bold mt-1">{{ $t('footer.agencies_and_concierges') }}</h3>
          <ul class="list-unstyled footer-links">
            <li class="p-2" v-for="(itemInf, index) in fourthSectionFooter" :key="index">
              <a href="#!" :href="itemInf.link" v-text="$t(itemInf.lang_key)"></a>
            </li>
          </ul>

        </div>
      </div>
      <div class="pt-3 border-top">
        <div>
          <p>{{ $t('footer.secure_payment_methods') }}:</p>
          <img height="20" src="/visa.png" alt="">
          <img height="30" src="/mastercard.png" alt="">
        </div>
        <div class="d-block mt-2 pt-2 border-top">
          <img class="mb-2" height="30" src="/secure-connection-icon.png" alt="">
          <p class="p-0 m-0 fw-bold">{{ $t('footer.information_footer') }}</p>
          <p class="p-0 m-0 text-secondary">{{ $t('footer.information_copy') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      images: {
        visa: `${process.env.VUE_APP_APP_URL}/src/assets/visa-icon.png`
      },
      socialMediaIcons: [
        'bi bi-facebook',
        'bi bi-twitter',
        'bi bi-instagram',
        'bi bi-youtube',
      ],
      informationSection: 1,
      firstSectionFooter: [
        {title: '¿Necesitas ayuda? Contáctanos ahora', link: '/', lang_key: 'footer.need_help'},
        {title: 'Formas de pago', link: '/', lang_key: 'footer.payment_methods'},
        {title: 'Bolsa de trabajo', link: '/', lang_key: 'footer.job_board'},
        {title: 'Aviso de privacidad', link: '/', lang_key: 'footer.privacy_notice'},
        {title: 'Términos y condiciones', link: '/', lang_key: 'footer.terms_and_conditions'},
        {title: 'Facturación', link: '/', lang_key: 'footer.billing'},
      ],
      secondSectionFooter: [
        {title: 'Soy agencia', link: '/', lang_key: 'footer.agency_text'},
        {title: 'Soy concierge', link: '/', lang_key: 'footer.concierge_text'},
        {title: 'Tengo un grupo', link: '/', lang_key: 'footer.group_text'},
        {title: 'Servicios corporativos', link: '/', lang_key: 'footer.corporative_services_text'},
        {title: 'Medios y Relaciones Públicas', link: '/', lang_key: 'footer.relations_text'},
        {title: 'Publicidad en globo aerostático', link: '/', lang_key: 'footer.publicity_text'},
        {title: 'Festivales y Otros', link: '/', lang_key: 'footer.festivals_text'},
      ],
      thirdSectionFooter: [
        {title: 'Iniciar sesión', link: '/login', lang_key: 'footer.signin_text'},
        {title: 'Registrarse', link: '/', lang_key: 'footer.signup_text'},
      ],
      fourthSectionFooter: [
        {title: 'Iniciar sesión', link: '/login', lang_key: 'footer.signin_text'},
        {title: 'Registrarse', link: '/', lang_key: 'footer.signup_text'},
      ],
      icons: [
        'facebook',
        'twitter',
        'linkedin',
        'instagram',
      ],
    }
  },
  methods: {
    changeLanguage(lang) {
      this.$router.push({path: `/${lang}${this.$route.path}`});
    }
  }
}
</script>