<template>
  <div>
    <div class="">
      <div class="custom-container">
        <a class="custom-link" href="#">
          <div class="custom-column ">
            <div class="column-content">
              <div class="icon">
                <i class="bi bi-shield-check"></i>
              </div>
              <div class="text-content">
                <div class="line1">Texto 1</div>
                <div class="line2">Texto 2</div>
              </div>
            </div>
          </div>
        </a>
        <a class="custom-link" href="#">
          <div class="custom-column">
            <div class="column-content">
              <div class="icon">
                <i class="bi bi-file-richtext-fill"></i>
              </div>
              <div class="text-content">
                <div class="line1">Texto 1</div>
                <div class="line2">Texto 2</div>
              </div>
            </div>
          </div>
        </a>
        <a class="custom-link" href="#">
          <div class="custom-column">
            <div class="column-content">
              <div class="icon">
                <i class="bi bi-egg"></i>
              </div>
              <div class="text-content">
                <div class="line1">Texto 1</div>
                <div class="line2">Texto 2</div>
              </div>
            </div>
          </div>
        </a>
      </div>


      <template v-if="images && images.length > 0">
        <VueSlickCarousel ref="slick" v-bind="slickOptions" class="home-carousel">
          <div
              @click="redirectActionBanner(image)"
              v-for="(image, index) in images"
              :style="{
                backgroundImage: `url(${image})`
               }"
              :key="index" class="item-slider px-2"></div>
        </VueSlickCarousel>
      </template>
    </div>

    <h3 class="pb-3 text-black mt-3 mb-3"><i class="bi bi-chevron-double-right"></i> {{ $t('home_section.additional_services_title') }}</h3>
    <div>
      <template v-if="additionalServices && additionalServices.length > 0">
        <VueSlickCarousel v-bind="slickOptionsServices" ref="slickserviceshome" class="mb-5 additional-services-carousel-home">
          <div v-for="service in additionalServices" :key="service.additional_service_id" class="px-2">
            <AServiceCardHome @service-details-opened="openServiceDetailsModal" :item-service="service"
                          :custom-class="'item-service-card'"/>
          </div>
        </VueSlickCarousel>
      </template>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="detailsAddSerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
         aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <template v-if="currentItemService">
              <h5 class="modal-title text-primary" id="exampleModalLabel">
                {{ currentItemService.additional_service_description_es }}</h5>
            </template>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <template v-if="currentItemService">
              <div class="row">
                <div class="col-lg-4 col-md-12">
                  <img :src="currentItemService.service_thumbnail.path_image_medium"
                       :alt="currentItemService.service_thumbnail.alt_image_text" class="img-fluid">
                </div>
                <div class="col-lg-8 col-md-12">
                  <h3 class="text-primary">{{ currentItemService.additional_service_description_es }}</h3>
                  <p class="text-dark">{{ currentItemService.additional_service_long_description_es }}</p>
                  <h5 class="pt-3 border-top text-dark" v-html="getFormattedPriceAddSer(currentItemService)"></h5>
                </div>
              </div>
            </template>
          </div>
          <div class="modal-footer">
            <p class="text-dark"><small>* Algunos servicios adicionales podrían incrementar su costo si es que no los
              adquiere a través de este sitio web al momento de finalizar su compra. Esto es debido a los convenios y
              promociones que sólo podrá adquirir a través de este sitio web.</small></p>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            <button @click="goAdditionalServicesHomePage" type="button" class="btn btn-light"><i
                class="bi bi-arrow-up-right"></i> Ver más servicios adicionales
            </button>
            <button type="button" class="btn btn-primary"><i class="bi bi-cart-plus"></i> Añadir ahora</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import AServiceCard from "@/views/templates/AServiceCard.vue";
import AServiceCardHome from "@/views/templates/AServiceCardHome.vue";

export default {
  name: 'RightHomeView',
  components: {
    AServiceCardHome,
    VueSlickCarousel,
    AServiceCard
  },
  data() {
    return {
      currentItemService: null,
      images: [
        'https://picsum.photos/1000/400',
        'https://picsum.photos/1000/400',
        'https://picsum.photos/1000/400',
      ],
      slickOptions: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000, // Cambia cada 3 segundos
        arrows: true,
        dots: true,
        prevArrow: '<button type="button" class="slick-prev">‹</button>',
        nextArrow: '<button type="button" class="slick-next">›</button>',
      },
      additionalServices: null,
      slickOptionsServices: {
        infinite: true,
        slidesToShow: 4, // Número de elementos en pantalla grande
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: true,
        arrows: false,
        prevArrow: '<button type="button" class="slick-prev">‹</button>',
        nextArrow: '<button type="button" class="slick-next">›</button>',
        responsive: [
          {
            breakpoint: 1024, // Para pantallas menores a 1024px (tableta y móviles)
            settings: {
              slidesToShow: 3, // Número de elementos en pantallas de tableta
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768, // Para pantallas menores a 768px (móviles)
            settings: {
              slidesToShow: 1, // Número de elementos en móviles
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  mounted() {
    this.getAdditionalServices()
  },
  methods: {
    redirectActionBanner(image){
      // handle banner click for redirect purposes
    },
    getFormattedPriceAddSer(servicePrice) {
      let priceFormatted = this.$number_format(servicePrice.additional_service_price_mxn, 0, '', ',');

      return `<strong>$${priceFormatted}</strong> <small>p/${servicePrice.unit_long_name_es}</small>`
    },
    goAdditionalServicesHomePage() {
      // Obtener el elemento del modal
      const modalElement = document.getElementById('detailsAddSerModal');

      // Verificar que el modalElement exista
      if (modalElement) {
        // Crear una instancia del modal de Bootstrap
        const modalInstance = bootstrap.Modal.getInstance(modalElement) || new bootstrap.Modal(modalElement);

        // Cerrar el modal
        modalInstance.hide();
      }

      const lang = localStorage.getItem('language') || 'en';
      this.$router.push({
        name: 'servicios-' + lang,
      });
    },
    getAdditionalServices() {
      this.$http.get(`/manage-services/flights/get-additional-services?limit=5`)
          .then((res) => {
            this.additionalServices = res.data.data

          }).finally(() => {
      })
    },
    openServiceDetailsModal(params = {}) {
      console.log(params)

      if (params.is_opened) {
        this.currentItemService = params.item_service

        const modalElement = document.getElementById('detailsAddSerModal');
        const modal = new bootstrap.Modal(modalElement);
        modal.show();
      }

    },
  }
}
</script>
<style>
.home-carousel {
  cursor: pointer;
}
.additional-services-carousel-home .slick-dots,
.home-carousel .slick-dots {
  position: absolute;
  bottom: -1em;
}

.additional-services-carousel-home .slick-arrow,
.home-carousel .slick-arrow {
  z-index: 999;
  background-color: #ffffff8f;
  border-radius: 100%;
  width: 40px;
  height: 40px;
}

.additional-services-carousel-home .slick-arrow.slick-prev,
.home-carousel .slick-arrow.slick-prev {
  left: 20px;
}

.additional-services-carousel-home .slick-arrow.slick-next,
.home-carousel .slick-arrow.slick-next {
  right: 25px;
}

.additional-services-carousel-home .slick-prev::before,
.home-carousel .slick-prev::before {
  color: #fff !important;
  content: "keyboard_backspace";
  font-family: "Material Icons";
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.additional-services-carousel-home .slick-next::before,
.home-carousel .slick-next::before {
  color: #fff !important;
  content: "trending_flat";
  font-family: "Material Icons";
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}


.item-slider {
  width: 100% !important;
  height: 400px;
  background-position: center;
  background-size: cover;
}
.home-carousel .slick-slide {
  display: inline-block !important;;
}
.custom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.custom-column {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.custom-column:not(:last-child) .column-content {
  border-right: 1px solid #a5a5a5;
}


.column-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.icon {

  font-size: 2rem; /* Tamaño del ícono */
  margin-right: 15px; /* Espacio entre el ícono y el texto */
}

.text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.line1 {
  font-weight: normal;
  text-align: left;
}

.line2 {
  font-weight: bold;
  text-align: left;
}

.line1,
.line2,
.icon {
  color: #555;
}

.custom-link {
  text-decoration: none; /* Elimina el subrayado del enlace */
  color: inherit; /* Hereda el color del texto del contenedor */
  display: block; /* Hace que el enlace ocupe el espacio del contenedor */
  width: 100%; /* Asegura que el enlace ocupe el ancho completo del contenedor */
  height: 100%; /* Asegura que el enlace ocupe la altura completa del contenedor */
}

.column-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.custom-link .column-content {
  display: flex; /* Mantiene el diseño flex del contenido */
  align-items: center;
  width: 100%;
  justify-content: center;
}


</style>