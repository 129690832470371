<template>
  <div>
<!--    <slick :options="slickOptionsBanners">
      <div v-for="i in 4" :key="i" class="slider-item">
        <a href="#!">
          <div class="banner-image" :style="{ backgroundImage: `url('https://via.placeholder.com/1920x300.png?text=Imagen')` }">
            <button class="btn btn-lg btn-primary">Comprar</button>
          </div>
        </a>
      </div>
    </slick>-->

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4 ">
          <div class="left-section-home">
            <LeftHomeView></LeftHomeView>
          </div>
        </div>
        <div class="col-lg-8 p-0 m-0 text-white">
          <div class="right-section-home p-0 m-0">
            <RightHomeView></RightHomeView>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import RightHomeView from "@/views/templates/RightHomeView.vue";
import LeftHomeView from "@/views/templates/LeftHomeView.vue";

export default {
  name: 'HomeView',
  components: {LeftHomeView, RightHomeView},
  data() {
    return {
      slickOptionsBanners: {
        centerMode: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: true,
        arrows: true
      },
    }
  },
  mounted() {
    console.log('render-event executed')
    document.dispatchEvent(new Event('render-event'));
  }
}
</script>
<style>
</style>
