<template>
  <div class="container pt-5 pb-5" v-if="!loadingFlight">
    <div class="flight-container">
      <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a @click="homeRedirect()" href="#">Home</a></li>
          <li class="breadcrumb-item"><a @click="flightsRedirect()" href="#">Vuelos</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{ currentItemFlight.flight_name }}</li>
        </ol>
      </nav>
      <span class="badge bg-primary">Más reservado</span>
      <RaitingStars :fill="4.5"></RaitingStars>
      <h1 class="text-primary fw-normal">{{ currentItemFlight.flight_name }}</h1>
      <div class="row">
        <!-- Columna con el contenido promocional que se moverá -->
        <div class="col-lg-5 order-lg-2">
          <!-- Slider Principal -->
          <div class="slider-container">
            <!-- Mover el contenido promocional en vistas móviles -->
            <div class="d-md-none">
              <span class="badge bg-danger">Promoción exclusiva</span>
              <p class="m-0 p-0">
                <span class="text-danger"><s>${{ getBeforePrice(currentItemFlight) }}</s></span>
                <span class="text-success">Ahorras: ${{ getSavingDifferencePrice(currentItemFlight) }}</span>
              </p>
              <p class="display-6" v-html="getFormattedPrice(currentItemFlight.flight_after_discount_price)"></p>
              <p class="lead">{{ currentItemFlight.flight_s_description }}</p>
            </div>

            <template v-if="currentItemFlight && currentItemFlight.flight_gallery">
              <VueSlickCarousel ref="mainGallery" :asNavFor="$refs.thumbnailsGallery" :focusOnSelect="true" v-bind="slickOptions">
                <div :key="itemFlightImage.id" v-for="(itemFlightImage, index) in currentItemFlight.flight_gallery">
                  <img class="img-fluid rounded-bottom" :data-image-id="index" :src="(itemFlightImage).path_image_medium" :alt="itemFlightImage.alt_image_text">
                </div>
              </VueSlickCarousel>
            </template>

            <template v-if="currentItemFlight && currentItemFlight.flight_gallery">
              <VueSlickCarousel ref="thumbnailsGallery" :asNavFor="$refs.mainGallery" :focusOnSelect="true" v-bind="slickOptionsThumbnails">
                <div :key="itemFlightImage.id" @click="clickThumbnail(index)" v-for="(itemFlightImage, index) in currentItemFlight.flight_gallery">
                  <img class="rounded-end" :data-image-id="index" :src="(itemFlightImage).path_image_small" :alt="itemFlightImage.alt_image_text">
                </div>
              </VueSlickCarousel>
            </template>


          </div>
        </div>

        <!-- Columna con el contenido de la promoción -->
        <div class="col-lg-7 order-lg-1">
          <!-- Mostrar el contenido promocional solo en pantallas grandes -->
          <div class="d-none d-md-block">
            <span class="badge bg-danger">Promoción exclusiva</span>
            <p class="m-0 p-0">
              <span class="text-danger"><s>${{ getBeforePrice(currentItemFlight) }}</s></span>
              <span class="text-success">Ahorras: ${{ getSavingDifferencePrice(currentItemFlight) }}</span>
            </p>
            <p class="display-6" v-html="getFormattedPrice(currentItemFlight.flight_after_discount_price)"></p>
            <p class="lead">{{ currentItemFlight.flight_s_description }}</p>
          </div>

          <div>
            <p class="border-top pt-3 pb-0 mb-10 text-primary"><strong>¿Qué incluye mi vuelo en globo?</strong></p>
            <FlightsItemsInclude :item-flight="currentItemFlight"></FlightsItemsInclude>
            <!-- Botón "Comprar ahora" -->
            <button
                @click="addFlightToCart(currentItemFlight)"
                type="button"
                class="btn btn-primary btn-lg d-none d-md-inline-block">
              <i class="bi bi-arrow-right"></i> Comprar ahora
            </button>
            <button
                @click="addFlightToCart(currentItemFlight)"
                type="button"
                class="btn btn-primary btn-md d-md-none w-100 mb-2">
              <i class="bi bi-arrow-right"></i> Comprar ahora
            </button>

            <!-- Botón "Recibir una cotización" -->
            <button
                @click="openModal"
                type="button"
                class="btn btn-outline-dark btn-lg ms-2 d-none d-md-inline-block">
              <i class="bi bi-info-circle"></i> Recibir una cotización
            </button>
            <button
                @click="openModal"
                type="button"
                class="btn btn-outline-dark btn-md w-100 d-md-none">
              <i class="bi bi-info-circle"></i> Recibir una cotización
            </button>

            <PaymentIcons></PaymentIcons>
          </div>
        </div>
      </div>

    </div>

    <div class="flight-more-details">
      <div class="mt-5 pt-5 border-top">
        <div >
          <h4 class="text-primary pb-3 pt-3">También te recomendamos estos vuelos en globo</h4>
          <MoreFlightsSlider :suggested-flights="suggestedFlights"></MoreFlightsSlider>
        </div>

        <div class="additional-services-container pt-5">
          <h4 class="text-primary pb-3 pt-3">Complementa tu experiencia con nuestros servicios adicionales</h4>
          <template v-if="additionalServices">
            <VueSlickCarousel v-bind="slickOptionsServices" ref="slickservices">
              <div v-for="service in additionalServices" :key="service.additional_service_id" class="px-2">
                <AServiceCardHome @service-details-opened="openServiceDetailsModal" :item-service="service" :custom-class="'item-service-card'" />
              </div>
            </VueSlickCarousel>
          </template>
        </div>

        <div >
          <h4 class="text-primary pb-3 pt-3">¿Quieres conocer la experiencia?</h4>
          <MeetTheExperience></MeetTheExperience>
        </div>

        <div>
          <h4 class="text-primary pb-3 pt-3"><strong>Preguntas frecuentes</strong></h4>
          <Faqs></Faqs>
        </div>

        <div v-if="currentItemFlight.flight_itinerary && currentItemFlight.flight_itinerary.length > 0">
          <h4 class="text-primary pb-3 pt-3"><strong>Conoce tu itinerario</strong></h4>
          <p>El itinerario es tentativo, queda sujeto a cambios en hora de cita, así como el estado meteorológico del día y logística de la actividad.</p>
          <ItineraryFlight :item-flight="currentItemFlight"></ItineraryFlight>
        </div>

        <div>
          <h4 class="text-primary pb-3 pt-3"><strong>Recomendaciones para tu vuelo en globo</strong></h4>
          <Recommendations></Recommendations>
        </div>

        <div>
          <h4 class="text-primary pb-3 pt-3"><strong><i class="bi bi-exclamation-triangle"></i> Restricciones</strong></h4>
          <Restrictions></Restrictions>
        </div>

        <div id="payment-methods-section">
          <h4 class="text-primary pb-3 pt-3"><strong>Conoce nuestros métodos de pago seguros</strong></h4>
          <PaymenMethods></PaymenMethods>
        </div>

      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Título del Modal</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="alert alert-info" role="alert">
              Recibe información más detallada sobre este vuelo, nuestro equipo se pondrá en contacto contigo a la brevedad. <strong>Los precios de nuestros servicios están sujetos a cambios sin previo aviso.</strong>
            </div>

            <div class="row">
              <div class="col-lg-2">
                <img :src="currentItemFlight.flight_thumbnail.path_image_small" :alt="currentItemFlight.flight_thumbnail.alt_image_text">
              </div>
              <div class="col-lg-10">
                <h5 class="m-0 p-0">{{ currentItemFlight.flight_name }}</h5>
                <p class="lead m-0 p-0" v-html="getFormattedPrice(currentItemFlight.flight_after_discount_price)"></p>
                <p>{{ currentItemFlight.flight_s_description }}</p>
              </div>
            </div>

<!--            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <img :src="currentItemFlight.flight_thumbnail.path_image_small" :alt="currentItemFlight.flight_thumbnail.alt_image_text">
              </div>
              <div class="flex-grow-1 ms-3">

              </div>
            </div>-->

            <div class="mt-3">
              <p class="m-0 p-0"><small><i class="bi bi-info-circle"></i> Todos los campos marcados en (*) son obligatorios</small></p>
            </div>

            <div class="mt-3">
              <div class="form-group">
                <div class="form-line">
                  <label for=""><strong>* Nombre completo:</strong></label>
                  <input type="text" class="form-control" placeholder="Ej: Juan Pérez" v-model="formInformation.full_name">
                </div>
              </div>
            </div>

            <div class="mt-3">
              <div class="form-group">
                <div class="form-line">
                  <label for=""><strong>* Correo electrónico:</strong></label>
                  <input type="email" class="form-control" placeholder="Ej: correo@example.com" v-model="formInformation.email">
                </div>
              </div>
            </div>

            <div class="mt-3">
              <div class="form-group">
                <div class="form-line">
                  <label for=""><strong>* Teléfono de contacto/Whatsapp/Teléfono fijo (agrega la lada del país):</strong></label>
                  <input type="text" class="form-control" placeholder="Ej: +525511223366" v-model="formInformation.phone_contact">
                </div>
              </div>
            </div>

            <div class="mt-3">
              <div class="form-group">
                <div class="form-line">
                  <label for=""><strong>* Posible fecha de vuelo:</strong></label>
                  <input type="date" class="form-control" v-model="formInformation.tentative_flight_date">
                </div>
              </div>
            </div>

            <div class="mt-3">
              <div class="form-group">
                <div class="form-line">
                  <label for=""><strong>* Número de {{currentItemFlight.flight_after_discount_price.unit_name}}(s). </strong></label>
                  <input type="number" class="form-control" v-model="formInformation.number_passengers">
                  <small><p class="text-primary"><i class="bi bi-info-circle"></i> Cada {{currentItemFlight.flight_after_discount_price.unit_name}} tiene un costo al día de hoy de ${{getOnlyPrice(currentItemFlight.flight_after_discount_price.flight_price)}}</p></small>
                </div>
              </div>
            </div>

            <div class="mt-3">
              <div class="form-group">
                <div class="form-line">
                  <label for="customer-checkbox">
                    <small><strong><input id="customer-checkbox" type="checkbox" v-model="formInformation.conditions_checkbox"> Al continuar, aceptas nuestro
                      <a href="#!">aviso de privacidad</a>.</strong></small>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            <button type="button" class="btn btn-primary" @click="storeCustomerInformationFLight">Confirmar y enviar</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="detailsAddSerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <template v-if="currentItemService">
              <h5 class="modal-title" id="exampleModalLabel">{{ currentItemService.additional_service_description_es }}</h5>
            </template>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <template v-if="currentItemService">
              <div class="row">
                <div class="col-lg-4 col-md-12">
                  <img :src="currentItemService.service_thumbnail.path_image_medium" :alt="currentItemService.alt_image_text" class="img-fluid">
                </div>
                <div class="col-lg-8 col-md-12">
                  <h3 class="text-primary">{{ currentItemService.additional_service_description_es }}</h3>
                  <p>{{ currentItemService.additional_service_long_description_es }}</p>
                  <h5 class="pt-3 border-top" v-html="getFormattedPriceAddSer(currentItemService)"></h5>
                </div>
              </div>
            </template>
          </div>
          <div class="modal-footer">
            <p><small>* Algunos servicios adicionales podrían incrementar su costo si es que no los adquiere a través de este sitio web al momento de finalizar su compra. Esto es debido a los convenios y promociones que sólo podrá adquirir a través de este sitio web.</small></p>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            <button @click="goAdditionalServicesHomePage" type="button" class="btn btn-light" ><i class="bi bi-arrow-up-right"></i> Ver más servicios adicionales</button>
            <button type="button" class="btn btn-primary" ><i class="bi bi-cart-plus"></i> Añadir ahora</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>

import VueSlickCarousel from 'vue-slick-carousel'
import FlightCard from "@/views/templates/FlightCard.vue";


import Recommendations from "@/views/templates/Recommendations.vue";
import Restrictions from "@/views/templates/Restrictions.vue";
import ItineraryFlight from "@/views/templates/ItineraryFlight.vue";
import Faqs from "@/views/templates/Faqs.vue";
import MeetTheExperience from "@/views/templates/MeetTheExperience.vue";
import FlightsItemsInclude from "@/views/templates/FlightsItemsInclude.vue";
import PaymentIcons from "@/views/templates/PaymentIcons.vue";
import PaymenMethods from "@/views/templates/PaymentMethods.vue";
import MoreFlightsSlider from "@/views/templates/MoreFlightsSlider.vue";
import QuoteFormFlight from "@/views/templates/QuoteFormFlight.vue";
import ServiceCarousel from "@/views/templates/ServiceCarousel.vue";
import AServiceCard from "@/views/templates/AServiceCard.vue";
import RaitingStars from "@/views/templates/RaitingStars.vue";
import AServiceCardHome from "@/views/templates/AServiceCardHome.vue";

export default {
  name: 'FlightDetails',
  components: {
    AServiceCardHome,
    RaitingStars,
    AServiceCard,
    ServiceCarousel,
    QuoteFormFlight,
    MoreFlightsSlider,
    PaymenMethods,
    PaymentIcons,
    FlightsItemsInclude,
    MeetTheExperience, Faqs, ItineraryFlight, Restrictions, Recommendations, FlightCard, VueSlickCarousel},
  props: ['itemFlight', 'slug'],
  data() {
    return {
      slickOptionsServices: {
        infinite: true,
        slidesToShow: 5, // Número de elementos en pantalla grande
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: true,
        arrows: false,
        prevArrow: '<button type="button" class="slick-prev">‹</button>',
        nextArrow: '<button type="button" class="slick-next">›</button>',
        responsive: [
          {
            breakpoint: 1024, // Para pantallas menores a 1024px (tableta y móviles)
            settings: {
              slidesToShow: 3, // Número de elementos en pantallas de tableta
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768, // Para pantallas menores a 768px (móviles)
            settings: {
              slidesToShow: 1, // Número de elementos en móviles
              slidesToScroll: 1
            }
          }
        ]
      },
      additionalServices: [],
      services: [
        { id: 1, image: 'https://via.placeholder.com/200', title: 'serviceo 1', price: 200 },
        { id: 2, image: 'https://via.placeholder.com/200', title: 'serviceo 2', price: 300 },
        { id: 3, image: 'https://via.placeholder.com/200', title: 'serviceo 3', price: 250 },
        { id: 4, image: 'https://via.placeholder.com/200', title: 'serviceo 1', price: 200 },
        { id: 5, image: 'https://via.placeholder.com/200', title: 'serviceo 2', price: 300 },
        { id: 6, image: 'https://via.placeholder.com/200', title: 'serviceo 3', price: 250 },
        // Añade más serviceos según sea necesario
      ],
      formInformation: {
        conditions_checkbox: false,
        number_passengers: 0,
        email: '',
        full_name: '',
        tentative_flight_date: '',
        phone_contact: '',
      },
      slickOptions: {
        arrows: false,
        infinite: false,
        autoplay: true, // Asegúrate de que la opción sea 'autoplay'
        autoplaySpeed: 2000,
        centerPadding: '0px',
        adaptiveHeight: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnFocus: true,
      },
      slickOptionsThumbnails: {
        centerPadding: '0px',
        adaptiveHeight: true,
        centerMode: false,
        slidesToShow: 4,
        slidesToScroll: 2,
        focusOnSelect: true,
        dots: true,
        infinite: false,
        arrows: true,
      },
      suggestedFlights: null,
      currentItemFlight: null,
      loadingFlight: true,
      currentItemService: null,
    }
  },
  watch: {
    slug(newSlug) {
      if (newSlug !== this.currentItemFlight?.flight_slug) {
        this.getFlightBySlug();
      }
    },
    itemFlight(newItemFlight) {
      if (newItemFlight) {
        this.currentItemFlight = newItemFlight;
        this.getSuggestedFlights();
      }
    }
  },
  beforeMount() {
    if(!this.slug) {
      const lang = localStorage.getItem('language') || 'en';
      this.$router.push({ name: 'home-' + lang});
    }

    this.getAdditionalServices()
  },
  mounted(){
    this.formInformation.tentative_flight_date = this.getTodayDate();
    this.loadingFlight = true
    if(this.itemFlight) {
      this.currentItemFlight = this.itemFlight
      this.getSuggestedFlights()
    }

    if(!this.itemFlight) {
      this.getFlightBySlug()
    }
  },
  methods: {
    addFlightToCart(itemFlight){
      // get localstorage
      let itemsCart = JSON.parse(localStorage.getItem('items-cart')) || {}
      // remove item flight if exists and replace to the new one
      //console.log(JSON.stringify(itemsCart))

      localStorage.setItem('items-cart', JSON.stringify(itemFlight))
      const lang = localStorage.getItem('language') || 'en';
      let checkoutUrl = `${lang}/checkout`
      Swal.fire({
        icon: 'success',
        title: 'Perfecto',
        html: `Hemos agregado <strong>${itemFlight.flight_name}</strong> a su carrito de compras. <a href="/${checkoutUrl}">Pagar ahora.</a>`,
        confirmButtonText: 'Cerrar'
      });
    },
    validateForm() {
      let errors = [];

      if(!this.formInformation.conditions_checkbox || this.formInformation.conditions_checkbox !== true) {
        errors.push('Aviso de Privacidad');
      }

      if(parseInt(this.formInformation.number_passengers) <= 0) {
        errors.push('Número de pasajeros');
      }

      // Validación del nombre completo
      if (!this.formInformation.full_name || typeof this.formInformation.full_name !== 'string') {
        errors.push('Nombre completo');
      }

      // Validación del correo electrónico
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.formInformation.email || typeof this.formInformation.email !== 'string' || !emailPattern.test(this.formInformation.email)) {
        errors.push('Correo electrónico');
      }

      // Validación del teléfono de contacto
      const phonePattern = /^\+\d{10,15}$/;
      if (!this.formInformation.phone_contact || !phonePattern.test(this.formInformation.phone_contact)) {
        errors.push('Teléfono de contacto');
      }

      // Validación de la fecha tentativa de vuelo
      if (!this.formInformation.tentative_flight_date || typeof this.formInformation.tentative_flight_date !== 'string') {
        errors.push('Fecha tentativa de vuelo');
      }

      // Retornar el array de errores
      return errors;
    },
    getTodayDate() {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Enero es 0
      const year = today.getFullYear();
      return `${year}-${month}-${day}`;
    },
    storeCustomerInformationFLight() {
      const validationErrors = this.validateForm();

      if (validationErrors.length === 0) {
        console.log(this.formInformation);

        this.$http.post(`/manage-services/store-information-customer`, {
          number_passengers: this.formInformation.number_passengers,
          email: this.formInformation.email,
          full_name: this.formInformation.full_name,
          tentative_flight_date: this.formInformation.tentative_flight_date,
          phone_contact: this.formInformation.phone_contact,
          flight_id: this.currentItemFlight.flight_id
        }).then((res) => {

          if(res.data.status) {
            // Si la validación es exitosa
            Swal.fire({
              icon: 'success',
              title: 'Éxito',
              text: 'Información guardada correctamente.',
            });

            this.formInformation.number_passengers = 0
            this.formInformation.email = ''
            this.formInformation.full_name = ''
            this.formInformation.tentative_flight_date = new Date()
            this.formInformation.phone_contact = ''
          }

        })



        // Aquí puedes agregar la lógica para guardar la información
      } else {
        // Si la validación falla
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por favor, completa los siguientes campos correctamente: ' + validationErrors.join(', '),
        });
      }
    },
    openServiceDetailsModal(params = {}){
      console.log(params)

      if(params.is_opened) {
        this.currentItemService = params.item_service

        const modalElement = document.getElementById('detailsAddSerModal');
        const modal = new bootstrap.Modal(modalElement);
        modal.show();
      }

    },
    openModal() {
      const modalElement = document.getElementById('exampleModal');
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    },

    getAdditionalServices() {
      this.$http.get(`/manage-services/flights/get-additional-services?limit=5`)
          .then((res) => {
            this.additionalServices = res.data.data

          }).finally(() => {
      })
    },
    getFlightBySlug(){
      this.$http.get(`/manage-services/flights/get-flights?flight-slug=${this.slug}`)
          .then((res) => {
            this.currentItemFlight = res.data.data[0]

          }).finally(() => {
            this.loadingFlight = false
            this.getSuggestedFlights()
      })
    },
    getSuggestedFlights(){
      this.$http.get(`/manage-services/flights/get-flights?recommended-flights=1&current-flight-id=${this.currentItemFlight.flight_id}&flight-categories-ids=1,2`)
          .then((res) => {
            this.suggestedFlights = res.data.data
          }).finally(() => {
        this.loadingFlight = false
        //this.getSuggestedFlights()
        /*const tooltipTriggerList = [].slice.call(this.$el.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));*/
      })
    },
    getSavingDifferencePrice(itemFlight){
      let difference = (parseFloat(itemFlight.flight_before_discount_price.flight_price) - parseFloat(itemFlight.flight_after_discount_price.flight_price)).toFixed(2)
      return this.$number_format(difference, 0, '', ',')
    },

    getFormattedPrice(mainPrice) {
      let priceFormatted = this.$number_format(mainPrice.flight_price, 0, '', ',');

      return `<strong>$${priceFormatted}</strong> <small>p/${mainPrice.unit_name}</small>`
    },
    getFormattedPriceAddSer(servicePrice) {
      let priceFormatted = this.$number_format(servicePrice.additional_service_price_mxn, 0, '', ',');

      return `<strong>$${priceFormatted}</strong> <small>p/${servicePrice.unit_long_name_es}</small>`
    },
    getOnlyPrice(price) {
      let priceFormatted = this.$number_format(price, 0, '', ',');

      return priceFormatted
    },
    getBeforePrice(mainPrice) {
      let priceFormatted = this.$number_format(mainPrice.flight_before_discount_price.flight_price, 0, '', ',');

      return priceFormatted
    },
    clickThumbnail(index){
      console.log(this.$refs.mainGallery)
      console.log(this.$refs.thumbnailsGallery)
      this.$refs.mainGallery.goTo(index)
    },
    goBack(stepBacks = -1) {
      this.$router.go(stepBacks); // Va a la página anterior en el historial
    },
    homeRedirect(stepBacks = -1) {
      const lang = localStorage.getItem('language') || 'en';

      this.$router.push({ name: 'home-' + lang });
      //this.$router.push({name: 'es/home'}); // Va a la página anterior en el historial
    },
    flightsRedirect(stepBacks = -1) {
      const lang = localStorage.getItem('language') || 'en';

      this.$router.push({ name: 'flights-' + lang });
    },
    goAdditionalServicesHomePage() {
      // Obtener el elemento del modal
      const modalElement = document.getElementById('detailsAddSerModal');

      // Verificar que el modalElement exista
      if (modalElement) {
        // Crear una instancia del modal de Bootstrap
        const modalInstance = bootstrap.Modal.getInstance(modalElement) || new bootstrap.Modal(modalElement);

        // Cerrar el modal
        modalInstance.hide();
      }

      const lang = localStorage.getItem('language') || 'en';
      this.$router.push({
        name: 'servicios-' + lang,
      });
    }
  }
}
</script>
