<template>
  <div class="card shadow-sm mb-4 custom-service-card">
    <img :src="getThumbnailService(itemService)"  :alt="itemService.alt_image_text">
    <div class="card-body">
      <h5 class="fw-normal card-title text-dark" :title="itemService.additional_service_description_es">
        {{ (itemService.additional_service_description_es) }}
      </h5>
      <h5 class="fw-semibold card-text ">
        {{ getFrontendPrice(itemService) }} <small class="custom-small-size">/{{itemService.unit_long_name_es}}</small>
      </h5>
      <button @click="addAddSerToCart(itemService)" class="btn btn-primary btn-sm d-block w-100">
        <i class="bi bi-cart-plus"></i> {{ $t('buttons.add_now') }}
      </button>
      <button @click="openDetailsAddSer(itemService)" class="btn btn-light btn-sm d-block w-100">
        <i class="bi bi-arrow-up-right"></i> {{ $t('buttons.view_more') }}
      </button>
    </div>
  </div>
</template>
<style scoped>
/* Default styles for mobile (screens smaller than 768px) */
.custom-service-card {
  width: 16rem; /* Default width for mobile devices */
}

/* Styles for tablets (screens between 768px and 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .custom-service-card {
    width: 20rem; /* Adjusted width for tablets */
  }
}

/* Styles for desktop screens (screens larger than 1024px) */
@media (min-width: 1024px) {
  .custom-service-card {
    width: 11rem; /* Adjusted width for desktops */
  }
}

/* Styles for extra-large screens (e.g., 1400px and above) */
@media (min-width: 1400px) {
  .custom-service-card {
    width: 15rem; /* Adjusted width for extra-large screens */
  }
}
</style>
<script>
export default {
  name: 'AServiceCardHome',
  props: ['itemService', 'customClass', 'fromHome'],
  methods: {
    addAddSerToCart(itemService) {
      // Obtener los elementos del localStorage
      let itemsAdditionalServicesCart = JSON.parse(localStorage.getItem('items-additional-services-cart')) || [];

      // Verificar si el item ya existe en el array
      const exists = itemsAdditionalServicesCart.some(service => parseInt(service.additional_service_id) === parseInt(itemService.additional_service_id));

      // Si no existe, añadirlo al array
      if (!exists) {
        itemService['quantities'] = 1
        itemsAdditionalServicesCart.push(itemService);

        // Guardar el array actualizado en el localStorage
        localStorage.setItem('items-additional-services-cart', JSON.stringify(itemsAdditionalServicesCart));
      } else {
        console.log('El servicio ya está en el carrito');
      }

      // Para depuración, puedes imprimir el contenido actualizado del localStorage
      console.log(localStorage.getItem('items-additional-services-cart'));
    },
    openDetailsAddSer(itemService) {
      this.$emit('service-details-opened', {
        is_opened: true,
        item_service: itemService
      })
    },
    getFrontendPrice(servicePrice) {
      return `$${this.$number_format(servicePrice.additional_service_price_mxn, 0, '', ',')}`;
    },
    getFormattedPrice(servicePrice) {
      let priceFormatted = this.$number_format(servicePrice.additional_service_price_mxn, 0, '', ',');

      return `<strong>$${priceFormatted}</strong> <br><small>p/${servicePrice.unit_long_name_es}</small>`
    },
    abbreviateText(text) {
      if(this.fromHome && this.fromHome !== false) {
        return text;
      }

      const maxLength = 10;
      if (text.length > maxLength) {
        return text.slice(0, maxLength).trim() + '...';
      }
      return text;
    },
    getThumbnailService(itemService) {
      if(!itemService.service_thumbnail) {
        return 'https://via.placeholder.com/50x50.png?text=Unavailable'
      }
      return itemService.service_thumbnail.path_image_small
    }
  }
}
</script>

<style scoped>

</style>
