
<template>
  <div>
    <QuickReservationForm></QuickReservationForm>
  </div>
</template>
<script >

import {defineComponent} from "vue";
import QuickReservationForm from "@/views/templates/QuickReservationForm.vue";

export default defineComponent({
  components: {QuickReservationForm}
})
</script>

<style scoped>

</style>