import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import Flights from '../views/internals/Flights.vue';
import FlightDetails from '../views/internals/FlightDetails.vue';
import i18n from '../i18n';
import Services from "@/views/internals/Services.vue";
import ServiceDetails from "@/views/internals/ServiceDetails.vue";
import {useHead} from '@unhead/vue';
import CheckoutView from "@/views/internals/CheckoutView.vue";

Vue.use(VueRouter);

const routesEn = [
    {
        path: '/en/checkout/:salesQuoteId?',
        name: 'checkout-en',
        component: CheckoutView,
        props: true,
    },
    {
        path: '/en/checkout/:salesQuoteId?',
        name: 'checkout-en',
        component: CheckoutView,
        props: true,
    },
    {
        path: '/en/home',
        name: 'home-en',
        component: HomeView,
        meta: {
            title: `Home ${process.env.VUE_APP_SEPARATOR_STRING} ${process.env.VUE_APP_SITE_NAME}`,
            metaTags: [
                {
                    name: 'description',
                    content: '¡El mejor vuelo en globo está aquí! Globos Aerostaticos todos los días | Vive nuevas experiencias con globos Teotihuacan ¡Reserva Hoy tu Vuelo en Globo!'
                },
                {
                    property: 'og:title',
                    content: `Home ${process.env.VUE_APP_SEPARATOR_STRING} Vuelos en Globo Teotihuacan`
                },
                {
                    property: 'og:description',
                    content: '¡El mejor vuelo en globo está aquí! Globos Aerostaticos todos los días | Vive nuevas experiencias con globos Teotihuacan ¡Reserva Hoy tu Vuelo en Globo!'
                },
                {
                    property: 'og:image',
                    content: `${process.env.VUE_APP_APP_URL}/skyballoons-mexico-vuelos-en-globo.jpg`
                },
                {
                    property: 'og:url',
                    content: `${process.env.VUE_APP_APP_URL}/es/home`
                },
                {
                    name: 'twitter:card',
                    content: 'summary_large_image',
                },
                {
                    name: 'twitter:title',
                    content: `Home ${process.env.VUE_APP_SEPARATOR_STRING} Vuelos en Globo Teotihuacan`
                },
                {
                    name: 'twitter:description',
                    content: '¡El mejor vuelo en globo está aquí! Globos Aerostaticos todos los días | Vive nuevas experiencias con globos Teotihuacan ¡Reserva Hoy tu Vuelo en Globo!'
                },
                {
                    name: 'twitter:image',
                    content: `${process.env.VUE_APP_APP_URL}/skyballoons-mexico-vuelos-en-globo.jpg`
                }
            ]
        }
    },
    {
        path: '/en/flights',
        name: 'flights-en',
        component: Flights
    },
    {
        path: '/en/flights/:slug',
        name: 'flight-details-en',
        component: FlightDetails,
        props: route => ({
            slug: route.params.slug,
            itemFlight: route.params.itemFlight ? JSON.parse(route.params.itemFlight) : null
        })
    },
    {
        path: '/en/services',
        name: 'services-en',
        component: Services
    },
    {
        path: '/en/sobre-nosotros',
        name: 'about-us-en',
        component: Services
    },
];

const routesEs = [
    {
        path: '/es/checkout/:salesQuoteId?',
        name: 'checkout-es',
        component: CheckoutView,
        props: true,
    },
    {
        path: '/es/home',
        name: 'home-es',
        component: HomeView,
        meta: {
            title: `Home ${process.env.VUE_APP_SEPARATOR_STRING} ${process.env.VUE_APP_SITE_NAME}`,
            metaTags: [
                {
                    name: 'description',
                    content: '¡El mejor vuelo en globo está aquí! Globos Aerostaticos todos los días | Vive nuevas experiencias con globos Teotihuacan ¡Reserva Hoy tu Vuelo en Globo!'
                },
                {
                    property: 'og:title',
                    content: `Home ${process.env.VUE_APP_SEPARATOR_STRING} Vuelos en Globo Teotihuacan`
                },
                {
                    property: 'og:description',
                    content: '¡El mejor vuelo en globo está aquí! Globos Aerostaticos todos los días | Vive nuevas experiencias con globos Teotihuacan ¡Reserva Hoy tu Vuelo en Globo!'
                },
                {
                    property: 'og:image',
                    content: `${process.env.VUE_APP_APP_URL}/skyballoons-mexico-vuelos-en-globo.jpg`
                },
                {
                    property: 'og:url',
                    content: `${process.env.VUE_APP_APP_URL}/es/home`
                },
                {
                    name: 'twitter:card',
                    content: 'summary_large_image',
                },
                {
                    name: 'twitter:title',
                    content: `Home ${process.env.VUE_APP_SEPARATOR_STRING} Vuelos en Globo Teotihuacan`
                },
                {
                    name: 'twitter:description',
                    content: '¡El mejor vuelo en globo está aquí! Globos Aerostaticos todos los días | Vive nuevas experiencias con globos Teotihuacan ¡Reserva Hoy tu Vuelo en Globo!'
                },
                {
                    name: 'twitter:image',
                    content: `${process.env.VUE_APP_APP_URL}/skyballoons-mexico-vuelos-en-globo.jpg`
                }
            ]
        }
    },
    {
        path: '/es/vuelos',
        name: 'flights-es',
        component: Flights
    },
    {
        path: '/es/vuelos/:slug',
        name: 'flight-details-es',
        component: FlightDetails,
        props: route => ({
            slug: route.params.slug,
            itemFlight: route.params.itemFlight ? JSON.parse(route.params.itemFlight) : null
        })
    },
    {
        path: '/es/servicios-adicionales',
        name: 'services-es',
        component: Services
    },
    {
        path: '/es/sobre-nosotros',
        name: 'about-us-es',
        component: Services
    },
    {
        path: '/es/servicios-adicionales/:slug',
        name: 'servicios-details-es',
        component: ServiceDetails,
        props: route => ({
            slug: route.params.slug,
            itemService: route.params.itemService ? JSON.parse(route.params.itemService) : null
        })
    }
];

const defaultRoute = [
    {
        path: '*',
        redirect: (to) => {
            const lang = localStorage.getItem('language') || 'es';
            return `/${lang}/home`;
        }
    }
];

const routes = [
    ...routesEn,
    ...routesEs,
    ...defaultRoute
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        // Si hay una posición guardada, vuelve a ella
        if (savedPosition) {
            return savedPosition;
        } else {
            // En caso contrario, desplázate al inicio de la página
            return {x: 0, y: 0};
        }
    }
});

// Utiliza un guardia global afterEach para ajustar el comportamiento del scroll
router.afterEach((to, from) => {
    // Lógica para asegurarte de que el scroll se desplace al inicio de la página
    Vue.nextTick(() => {
        window.scrollTo(0, 0);
    });

    // Lógica para manejar la actualización de meta tags y título
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // Actualizar el título de la página
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    }

    // Eliminar cualquier etiqueta meta existente
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).forEach(el => el.parentNode.removeChild(el));

    // Añadir las nuevas etiquetas meta
    if (nearestWithMeta) {
        nearestWithMeta.meta.metaTags.forEach(tagDef => {
            const tag = document.createElement('meta');

            Object.keys(tagDef).forEach(key => {
                tag.setAttribute(key, tagDef[key]);
            });

            // Para evitar conflictos, marca esta etiqueta meta para que sea fácilmente identificable y eliminable
            tag.setAttribute('data-vue-router-controlled', '');

            document.head.appendChild(tag);
        });
    }
});


router.beforeEach((to, from, next) => {
    const lang = to.path.split('/')[1];
    if (['en', 'es'].includes(lang)) {
        localStorage.setItem('language', lang);
        i18n.locale = lang;
        next();
    } else {
        const defaultLang = localStorage.getItem('language') || 'es';
        next(`/${defaultLang}/home`);
    }
});

export default router;

